Sofoot.modules.reactions = (() => {
    let wrapper, loaded, hashJumpOk, app, logged, timing;
    function startTiming() {
        timing = 0;
        continueTiming(1);
    }
    function continueTiming(delta) {
        timing += delta;
        console.log('⏱️', timing);

        setTimeout(e => continueTiming(delta), delta * 1000)
    }
    // window.addEventListener('beforeunload', (event) => {
    //     const temps = Math.floor(timing / 10) * 10;
    //     console.log('⏱️✅', temps + 's');
    //     // fathom.trackEvent('⏱️' + temps + 's ' + getCanonicalUri());
    // });

    return {
        commencer() {

            if (!loaded && REACTION_BASEURL) {
                document.addEventListener('scroll-end', () => {
                    let top = document.querySelector('#reactions-wrapper').offsetTop;
                    if (App.modules.scroll.defilement.center > top) {
                        if (!logged) {
                            logged = true;
                            // startTiming();
                            fathom.trackEvent('💬 ' + getCanonicalUri());
                            if (Sofoot.modules.compte.isConnected()) {
                                fathom.trackEvent('✅' + getCanonicalUri());
                            }
                        }
                        document.body.dataset.noNav = true;
                    } else {
                        delete document.body.dataset.noNav;
                    }
                });

                // document.removeEventListener('scroll', Sofoot.modules.reactions.commencer);

                loaded = true;
                document.body.dataset.reactions = 'ok';
                // $('body').attr('data-reactions', 'ok');
                console.log('Démarrage Réactions')

                script_load(REACTION_BASEURL + 'static/embed.js', function () {
                    Sofoot.modules.reactions.charger();
                }, false, true)
            }
        },

        hashJump() {
            if (!hashJumpOk) {
                hashJumpOk = true
                if (document.location.hash.includes('comment-')) {
                    let commId = document.location.hash.split('-')[1];
                    console.log('hashJump', commId)
                    if (commId) {
                        window.reactions.send('action', { name: 'scroll-to-comment', params: { commId: commId, focus: true } });
                    }
                }
            }
        },

        /**
         * envoye le token jwt dans l'iframe
         * ne peux etre lancé qu'une fois toutes les 5 secondes
         */
        sendJwtToIframe() {

            try {

                if (Sofoot.modules.compte.isConnected()) {

                    const tmpToken = sessionStorage.getItem('jwt-token');
                    const tokenTime = sessionStorage.getItem('jwt-token-time');
                    const currentTime = Date.now();
                    const oneHour = 3600000; // 1 heure en millisecondes

                    if (urgence && tmpToken && tokenTime && currentTime - tokenTime < oneHour) {
                        window.reactions.send('action', { name: 'load-token', params: { token: tmpToken } });
                    } else {
                        _api.post('/api/jwt', { storyId: wrapper.dataset.storyId }).then(data => {
                            // sessionStorage.setItem('jwt-token', data.token);
                            // sessionStorage.setItem('jwt-token-time', Date.now());
                            window.reactions.send('action', { name: 'load-token', params: { token: data.token } });
                        });
                    }

                }
            } catch (e) {
                // desactiver = false;
                console.log('sendJwtToIframe', e);
            }
        },
        doStoryLoaded() {
            console.log('doStoryLoaded');
            Sofoot.modules.reactions.hashJump();
            Sofoot.modules.reactions.sendJwtToIframe();
            window.reactions.send('action', { name: 'dark-mode-toggle', params: { scheme: App.modules.darkMode.getScheme() } });
        },
        charger() {

            document.addEventListener('dark-mode-toggle', (e) => {
                window.reactions.send('action', { name: 'dark-mode-toggle', params: { scheme: e.data.scheme } });
            })

            let back = encodeURIComponent(document.location.pathname);

            const params = {
                customCss: URL_SITE + 'reactions/reactions.css',
                reactionsUrl: REACTION_BASEURL + '/sofoot/',
                isLive: false, // TODO
                storyId: wrapper.dataset.storyId,
                storyUrl: getCanonical(),
                storyTitle: wrapper.dataset.titre,
                preferedColorScheme: App.modules.darkMode.getScheme(),
                iframeLoaded(e, iframe) {
                    setTimeout(Sofoot.modules.reactions.doStoryLoaded, 2000);
                },
                events: {
                    storyLoaded() {
                        Sofoot.modules.reactions.doStoryLoaded();
                    },
                    // scrollTo: function (offsetTop) {
                    //     // TODO virer jquery
                    //     let top = $(reactions.reactionsIframe).offset().top + offsetTop - ($(window).height() / 3)

                    //     window.scrollTo(0, top);

                    // },

                    scrollTo: function (offsetTop) {
                        let reactionsIframe = document.querySelector(reactions.reactionsIframe);
                        let top = reactionsIframe.offsetTop + offsetTop - (window.innerHeight / 3);

                        window.scrollTo({
                            top: top,
                            left: 0,
                            behavior: 'smooth'
                        });
                    },

                    // scrollTo: function (offsetTop) {
                    //     let reactionsIframe = document.querySelector(reactions.reactionsIframe);
                    //     let top = reactionsIframe.offsetTop + offsetTop - (window.innerHeight / 3);

                    //     window.scrollTo({
                    //         top: top,
                    //         left: 0,
                    //         behavior: 'smooth'
                    //     });
                    // },
                    jumpTo: function (commId) {
                        reactions.send('action', { name: 'scroll-to-comment', params: { commId: commId } });
                    },
                    setAvatar: function () {
                        window.open("/compte", '_self');
                    },
                    userAdmin: function (member) {
                        window.open('/admin/users.php?s=' + encodeURIComponent(member.email), '_blank');
                    },
                    logOut: function () {
                        window.open("/logout?back=" + back, '_self');
                    },
                    logIn: function () {
                        //position.set();
                        window.open("/login?back=" + (back + '%23commentaires'), '_self');
                    },
                    storyUpdated: function (story) {
                        // TODO
                        console.log(story);
                    },
                    afterPost: function (data) {
                        fetch(`/reactions/comments/${article.id}?` + new URLSearchParams(data))
                    },
                    directLink: function (commtId) {
                        prompt('Lien direct vers ce commentaire', getCanonical() + "#comment-" + commtId)
                    },
                    signUp: function () {
                        window.open("/compte/inscription?back=true", '_self');
                    },
                    rulesPage: function () {
                        window.open('/bonne-conduite.html', '_blank');
                    },

                    bannedWordAttempt: function (payload) {
                        _api.post('https://tools.sopress.net/alertes-moderation/', { name: 'bannedWordAttempt', payload: payload })
                    },
                    reportComment: function (alerte) {
                        message = prompt("Pour quelle raison voulez-vous signaler ce message ?");
                        if (message) {
                            alerte.message = message;
                            alerte.url = document.location.href.split('#')[0];
                            _api.post('https://tools.sopress.net/alertes-moderation/', alerte).then(function () {
                                alert('Merci de votre signalement. Cette alerte a été transmise à notre équipe de modération et sera traitée au plus tôt.')
                            })
                        }

                    }

                }
            };
            console.log('Reactions', params);
            window.reactions = Reactions(params);
            console.log('window.reactions', window.reactions)
        },

        start() {
            wrapper = document.querySelector('#reactions-wrapper');
            if (wrapper) {
                let hash = document.location.hash.replace('#', '');
                if (wrapper.offsetTop < window.innerHeight) { // le bloc de commentaire est déjà visible ?
                    Sofoot.modules.reactions.commencer();
                } else if (hash.includes('comment-')) { // on pointe vers un commentaire précis ?
                    Sofoot.modules.reactions.commencer();
                    // } else if (App.modules.scroll.defilement) { // la page est déjà scrollée
                    //     Sofoot.modules.reactions.commencer();
                } else {
                    if (typeof urgence != 'undefined' && urgence) {
                        document.addEventListener('scroll', Sofoot.modules.reactions.commencer, { once: true });
                    } else {
                        document.addEventListener('scroll-start', Sofoot.modules.reactions.commencer, { once: true });
                    }
                }
                window.addEventListener('hashchange', (event) => {
                    Sofoot.modules.reactions.commencer();
                });
            }

        }
    }
})();



