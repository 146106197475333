// https://stackoverflow.com/a/44562952
function swapNodes(n1, n2) {

    var p1 = n1.parentNode;
    var p2 = n2.parentNode;
    var i1, i2;

    if ( !p1 || !p2 || p1.isEqualNode(n2) || p2.isEqualNode(n1) ) return;

    for (var i = 0; i < p1.children.length; i++) {
        if (p1.children[i].isEqualNode(n1)) {
            i1 = i;
        }
    }
    for (var i = 0; i < p2.children.length; i++) {
        if (p2.children[i].isEqualNode(n2)) {
            i2 = i;
        }
    }

    if ( p1.isEqualNode(p2) && i1 < i2 ) {
        i2++;
    }
    p1.insertBefore(n2, p1.children[i1]);
    p2.insertBefore(n1, p2.children[i2]);
}

function chargerUrl(url, callback, args={}) {
    let cache = args.cache || 'default';
    fetch(url, { cache: cache }).then(response => response.text()).then(html => {
        let dom = document.implementation.createHTMLDocument().body;
        dom.innerHTML = html;
        callback(dom);
    });
}

function getCanonicalUri() {
    return new URL(getCanonical())?.pathname;
}

/**
 * Retourne l'url canonique d'une page en allant la chercher dans le <head> ou en la construisant a partir de document.location.href
 * @returns string L'url canonique de la page
 */
function getCanonical() {
    const link = document.querySelector('link[rel=canonical]')
    if (link) {
        return link.getAttribute('href');
    } else {
        _tmp = document.location.href.split('?')[0].split('#')[0]
        // _tmp = document.location.href.split('#')[0];
        return _tmp;
    }
}




function popupCenter({ url, title, w, h }) {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    w = w || width * 50 / 100;
    h = h || height * 80 / 100;
    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title,
        `
      resizable=yes,
      status=no,
      location=no,
      toolbar=no,
      menubar=no,
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )

    if (window.focus) newWindow.focus();
}