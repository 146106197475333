Sofoot.modules.lazy = (() => {
    let items, observer;

    return {
        doLiens() {
            document.querySelectorAll('a[data-cible]').forEach(lien => {
                lien.href = lien.dataset.cible;
                delete lien.dataset.cible;
            })
        },
        doLoad(item) {
            console.log('doLoad', item);
            item.innerHTML = item.querySelector('p').innerText;
            item.removeAttribute('class');
            let iframe = item.querySelector('iframe');
            console.log(iframe)
            let w = iframe.getAttribute('width');
            let h = iframe.getAttribute('height');
            if (w && h) {
                let ar = w / h;
                iframe.style.width = '100%';
                iframe.style.height = 'auto';
                iframe.style.aspectRatio = ar;
                observer.unobserve(item);
            }
        },
        lazyLoadItems() {
            if ("IntersectionObserver" in window) {
                observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        const { isIntersecting, intersectionRatio } = entry;
                        if (isIntersecting) {
                            Sofoot.modules.lazy.doLoad(entry.target)
                        }
                    });
                });

                items.forEach(item => observer.observe(item));
            } else {
                items.forEach(item => {
                    item.innerHTML = item.querySelector('p').innerText;
                });
            }
        },
        start() {
            console.log("lazy")
            this.doLiens();
            let article = document.querySelector('article[data-zone="article"]');
            if (article) {
                console.log('lazy')
                items = article.querySelectorAll('.html-lazy');
                this.lazyLoadItems();
            }
        }
    }
})();
